.root {
	--backgroundColor: var(--token-color-surface-primary);
	--itemBackgroundColor: var(--token-color-surface-primary);
	--itemWidth: minmax(125px, 160px);
	--columns: 2;

	composes: g-grid-container from global;
	display: flex;
	flex-direction: column;
	row-gap: var(--hdsplus-spacing-07);

	@media (--medium-up) {
		--itemWidth: 213px;
	}

	@media (--large) {
		--columns: 4;
	}

	&.dark {
		--itemBackgroundColor: var(--token-color-surface-interactive-hover);
	}

	&.faint {
		--backgroundColor: var(--token-color-surface-faint);
	}
}

.eyebrow {
	composes: hdsplus-typography-label from global;
	color: var(--token-color-foreground-faint);
	text-transform: uppercase;
	text-align: center;
	font-weight: 600;
	margin-bottom: var(--hdsplus-spacing-03);

	@media (--medium-up) {
		margin-bottom: var(--hdsplus-spacing-04);
	}

	@media (--large) {
		margin-bottom: var(--hdsplus-spacing-05);
	}
}

.headline {
	color: var(--token-color-foreground-strong);
	text-align: center;
	margin: 0;
	font-family: var(--hdsplus-typography-display-expressive-300-font-family); 
	font-size: var(--hdsplus-typography-display-expressive-300-font-size); 
	line-height: var(--hdsplus-typography-display-expressive-300-line-height); 
	letter-spacing: var(--hdsplus-typography-display-expressive-300-letter-spacing); 
	font-weight: 700;
	
	@media (--medium-up) {
		font-family: var(--hdsplus-typography-display-expressive-400-font-family); 
		font-size: var(--hdsplus-typography-display-expressive-400-font-size); 
		line-height: var(--hdsplus-typography-display-expressive-400-line-height); 
		letter-spacing: var(--hdsplus-typography-display-expressive-400-letter-spacing); 
	}
	
	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-500-font-family); 
		font-size: var(--hdsplus-typography-display-expressive-500-font-size); 
		line-height: var(--hdsplus-typography-display-expressive-500-line-height); 
		letter-spacing: var(--hdsplus-typography-display-expressive-500-letter-spacing); 
	}
}

.listReset {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.gridItems {
	composes: listReset;
	composes: g-grid-container from global;
	display: grid;
	justify-content: center;
	align-items: center;
	grid-template-columns: repeat(var(--columns), var(--itemWidth));
	row-gap: var(--hdsplus-spacing-07);
	column-gap: var(--hdsplus-spacing-06);
}

.marquee {
	position: relative;
	display: flex;
	width: 100%;
	overflow-x: hidden;

	&::before {
		z-index: 1;
		position: absolute;
		inset: 0;
		content: '';
		width: var(--hdsplus-spacing-10);
		height: 100%;
		background: linear-gradient(to right, var(--backgroundColor), transparent);
	}

	&::after {
		z-index: 1;
		position: absolute;
		top: 0;
		right: 0;
		content: '';
		width: var(--hdsplus-spacing-10);
		height: 100%;
		background: linear-gradient(to right, transparent, var(--backgroundColor));
	}
}

.items {
	composes: listReset;
	will-change: transform;
	display: flex;
	flex: 0 0 auto;
	animation-name: scroll;
	animation-duration: var(--duration);
	animation-timing-function: linear;
	animation-iteration-count: infinite;

	@media (prefers-reduced-motion) {
		animation: none;
	}
}

@keyframes scroll {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(-100%);
	}
}

.item {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	height: 60px;
	padding: var(--hdsplus-spacing-05) var(--hdsplus-spacing-04);
	
	@media (--medium-up)  {
		padding: var(--hdsplus-spacing-02) var(--hdsplus-spacing-07);
		height: var(--hdsplus-spacing-13);
		margin-right: var(--hdsplus-spacing-07);
	}

	& img {
		max-width: 100%;
		max-height: 100%;
		width: auto;
		height: auto;
	}
}
